/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="page">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          flex: 1,
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '20vh',
          zIndex: 3
        }}
      >
        {children}
      </main>

      <footer
        style={{
          marginTop: `2rem`,
        }}
      >
        <span>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </span>
        <span>
          <a href="https://unsplash.com/photos/qrdYYSLzGuY">
            Image by Niklas Ohlrogge at Unsplash
          </a>
        </span>
      </footer>
      <div class="grad"></div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
