import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from "../images/logo_transparent.png" 

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
      zIndex: 3,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1
        style={{
          margin: 0,
          fontSize: "3rem",
          opacity: '80%',
          textShadow:
            "#f4e6cf 0 0 25px",
        }}
      >
        <Link
          to="/"
          style={{
            color: `#fffde4`,
            textDecoration: `none`,
            display: 'inline-block'
          }}
        >
          <img src={logo} alt={siteTitle} style={{maxHeight: '3rem', padding: 0, margin: 0}} />
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
